
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import { Game } from "./CodeNameTypes";

@Options({
  props: {
    game: Object as PropType<Game>,
  },
})
export default class GameOver extends Vue {
  game!: Game;

  get winnerTeam() {
    if (this.game.team_a.is_winner) return "RED!";
    else return "BLUE!";
  }

  get winnerTextStyle() {
    let style = {
      color: "#ff5959",
    };
    if (!this.game.team_a.is_winner) style.color = "#59d0ff";
    return style;
  }

  emitRestart() {
    this.$emit("restart");
  }

  emitEndGame() {
    this.$emit("end");
  }
}
