
import { Options, Vue } from "vue-class-component";
import API from "../utils/api";

@Options({
  props: {
    msg: String,
  },
})
export default class GameList extends Vue {
  msg!: string;
  gameList: { title: string; icon: string; id: string }[] = [];

  get games() {
    return this.gameList;
  }

  async mounted() {
    this.gameList = await API.getGameList();
    console.log("Loaded");
    console.log(JSON.stringify(this.gameList, null, 2));
    return true;
  }

  join() {
    this.$router.push({ path: `/game/CODE_NAME` });
  }

  async create(game_id: string) {
    console.log("Creating...");
    console.log(game_id);
    let res = await API.createGame(game_id);
    this.$router.push({ path: `/game/CODE_NAME/${res.game_session_id}` });
  }
}
