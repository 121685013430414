export default class API {
  static async getGameList(): Promise<gameList[]> {
    try {
      const res = await fetch("./api/game/list");
      return res.json();
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  static async createGame(id: string): Promise<gameSessionBase> {
    let e: Response;
    try {
      const res = await fetch("./api/game/create", {
        method: "POST",
        body: JSON.stringify({
          id,
        }),
      });
      if (res.ok) return res.json();
      e = res;
    } catch (e) {
      console.error(e);
      throw e;
    }
    throw await e.json();
  }

  static async getGame(sessionId: string): Promise<any> {
    let e: Response;
    try {
      const res = await fetch(`./api/game/${sessionId}`);
      if (res.ok) return res.json();
      e = res;
    } catch (e) {
      console.error(e);
      throw e;
    }
    throw await e.json();
  }

  static async joinGame(id: string, role: string): Promise<any> {
    let e: Response;
    try {
      const res = await fetch("./api/game/join", {
        method: "POST",
        body: JSON.stringify({
          id,
          role,
        }),
      });
      if (res.ok) return res.json();
      e = res;
    } catch (e) {
      console.error(e);
      throw e;
    }
    throw await e.json();
  }
}

interface gameSessionBase {
  game_session_id: string;
}

interface gameList {
  title: string;
  icon: string;
  id: string;
}
