import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2405684f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "board" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = {
  key: 0,
  class: "rotated"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.board, (row, i) => {
      return (_openBlock(), _createElementBlock(_Fragment, { key: i }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (word, j) => {
          return (_openBlock(), _createElementBlock("div", {
            key: j,
            onClick: ($event: any) => (_ctx.onWordClicked(word)),
            class: _normalizeClass(_ctx.getClass(word)),
            style: _normalizeStyle(_ctx.getProperStyle())
          }, [
            _createElementVNode("div", null, _toDisplayString(word.word), 1),
            (_ctx.role == 'ROLE_BOARD')
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(word.word), 1))
              : _createCommentVNode("", true)
          ], 14, _hoisted_2))
        }), 128))
      ], 64))
    }), 128))
  ]))
}