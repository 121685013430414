
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";

@Options({
  props: {
    availableRoles: Array as PropType<string[]>,
  },
})
export default class GameLobby extends Vue {
  availableRoles!: string[];

  roles: Record<string, string> = {
    ROLE_LEAD_A: "מנהיג אדום",
    ROLE_LEAD_B: "מנהיג כחול",
    ROLE_BOARD: "לוח",
    ROLE_BOARD_READ_ONLY: "לוח פסיבי",
  };
  emitRoleSelect(role: string) {
    this.$emit("onRoleSelect", role);
  }
}
