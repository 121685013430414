
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import { BoardWord } from "./CodeNameTypes";

@Options({
  props: {
    role: String,
    board: Array as PropType<BoardWord[][]>,
  },
})
export default class GamePlay extends Vue {
  role!: string;
  board!: BoardWord[][];
  mounted() {
    console.log(this.board);
  }
  getClass(word: BoardWord) {
    if (word.selected) {
      if (word.is_death_word) {
        return "death-word";
      } else {
        switch (word.color) {
          case "red":
            return "red-word";
          case "blue":
            return "blue-word";
          default:
            return "selected-word";
        }
      }
    } else {
      return "board-word";
    }
  }
  onWordClicked(word: BoardWord) {
    console.log(word);
    if (this.role != "ROLE_BOARD" || word.selected) return;
    this.$emit("on-word-select", word);
  }
  getProperStyle(): Record<string, string> {
    if (this.role != "ROLE_BOARD") return {};
    return {
      "text-align": "right",
      padding: "15px 25px",
    };
  }
}
