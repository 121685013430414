
import { Options, Vue } from "vue-class-component";
import API from "../utils/api";
import {
  Game,
  Team,
  BoardWord,
  SortedWords,
} from "../components/code_name/CodeNameTypes";

import GameLobby from "../components/code_name/GameLobby.vue";
import GamePlay from "../components/code_name/GamePlay.vue";
import GamePlayLeader from "../components/code_name/GamePlayLeader.vue";
import JoinGame from "../components/code_name/JoinGame.vue";
import GameOver from "../components/code_name/GameOver.vue";

@Options({
  components: {
    GameLobby,
    GamePlay,
    GamePlayLeader,
    JoinGame,
    GameOver,
  },
})
export default class CodeNameView extends Vue {
  STATE = {
    JOIN_GAME: 0,
    ROLE_SELECT: 1,
    GAME_PLAY: 2,
    GAME_PLAY_LEADER: 3,
    WAITING: 4,
    GAME_OVER: 5,
  };
  loading = true;
  state: number = this.STATE.JOIN_GAME;
  game_session_id = "";
  board: BoardWord[][] = [[]];
  game!: Game;
  role!: string;
  clientId!: string;
  currentTeam!: string;
  sortedWords: SortedWords = {};
  ws: WebSocket | null = null;

  async mounted() {
    console.log("Mounted...");
    this.loading = true;
    this.game_session_id = this.$route.params.game_session_id[0];
    this.state = this.STATE.JOIN_GAME;
    if (this.game_session_id && this.game_session_id !== "") {
      try {
        let g = await this.fetchGame();
        this.updateGameState(g);
      } catch (err: any) {
        this.game_session_id = "";
        this.$router.replace({ path: "/game/CODE_NAME/" });
        alert(err.message);
      }
    } else {
      console.log("Need to show input to join...");
    }

    this.loading = false;
  }

  currentTeamTextStyle() {
    let style = {
      color: "#ff5959",
    };
    if (!this.game.team_a.is_turn) style.color = "#59d0ff";
    return style;
  }

  isLeader() {
    return this.role && this.role.indexOf("LEAD") >= 0;
  }
  updateCurrentTeam() {
    if (this.game.team_a.is_turn) return this.game.team_a.name;
    return this.game.team_b.name;
  }

  async fetchGame() {
    let res = await API.getGame(this.game_session_id);
    return res;
  }

  keepAlive() {
    if (this.ws) {
      this.ws.send(
        JSON.stringify({
          type: "TYPE_PING",
          data: {},
        })
      );
      setTimeout(this.keepAlive, 1500);
    }
  }

  onChangeTurn() {
    if (this.ws) {
      this.ws.send(
        JSON.stringify({
          type: "TYPE_CHANGE_TURN",
          data: {},
        })
      );
    } else {
      console.log("no ws");
    }
  }

  onWordSelected(word: BoardWord) {
    if (this.state != this.STATE.GAME_PLAY || this.role != "ROLE_BOARD") return;
    if (this.ws) {
      this.ws.send(
        JSON.stringify({
          type: "TYPE_WORD_PICK",
          data: {
            word_id: word.id,
          },
        })
      );
    } else {
      console.log("no ws");
    }
  }

  onRestartClicked() {
    if (this.ws) {
      this.ws.send(
        JSON.stringify({
          type: "TYPE_RESTART_GAME",
          data: {},
        })
      );
    } else {
      console.log("no ws");
    }
  }

  async onJoinClicked(gameId: string) {
    try {
      this.game_session_id = gameId;
      let res = await this.fetchGame();
      this.$router.push(`/game/CODE_NAME/${this.game_session_id}`);
      this.updateGameState(res);
    } catch (err: any) {
      this.game_session_id = "";
      this.$router.replace({ path: "/game/CODE_NAME/" });
      alert(err.message);
    }
  }

  async onRoleSelect(role: string) {
    this.loading = true;
    try {
      let res = await API.joinGame(this.game.id, role);
      this.role = role;
      this.clientId = res.client_id;
      this.connectWs();
    } catch (err: any) {
      alert(err.message);
    }
    this.updateGameState(await this.fetchGame());
    this.loading = false;
  }

  connectWs() {
    if (!this.ws) {
      this.ws = new WebSocket(
        `${location.origin.replace(/^http/, "ws")}/ws/${this.game.id}/${
          this.clientId
        }`
      );
      this.ws.addEventListener("open", () => {
        console.log("WS connection open");
        this.keepAlive();
      });
      this.ws.addEventListener("close", () => {
        console.log("WS connection closed");
        this.ws = null;
        alert("Game was closed. Thanks For Playing!");
        this.$router.push("/");
      });
      this.ws.addEventListener("error", (err) => {
        console.error("WS ERROR:");
        console.error(err);
      });
      this.ws.addEventListener("message", (e: any) => {
        let msg = JSON.parse(e.data);
        if (msg.type == "TYPE_CLIENT_UPDATE") {
          this.role = msg.data.role;
          if (msg.data.sorted_words) {
            this.sortedWords = msg.data.sorted_words;
          }
          this.updateGameState(msg.data.game);
        } else if (msg.type == "TYPE_GAME_CLOSED") {
          this.state = this.STATE.GAME_OVER;
        }
        console.log(msg);
      });
    } else {
      console.log("WS not null!");
    }
  }

  updateGameState(game: Game) {
    this.game = game;
    this.board = game.board;
    this.currentTeam = this.updateCurrentTeam();
    if (!this.role) {
      this.state = this.STATE.ROLE_SELECT;
      return;
    }
    switch (this.game.state) {
      case "IDLE":
        break;
      case "ROLE_SELECT":
        if (!this.role) {
          this.state = this.STATE.ROLE_SELECT;
        } else {
          this.state = this.STATE.WAITING;
        }
        break;
      case "IN_PROGRESS":
        this.state = this.STATE.GAME_PLAY;
        if (this.role == "ROLE_LEAD_A" || this.role == "ROLE_LEAD_B") {
          this.state = this.STATE.GAME_PLAY_LEADER;
        }
        break;
      case "GAME_OVER":
        this.state = this.STATE.GAME_OVER;
        break;
    }
    console.log(this.game.state);
  }

  async joinGame(gs_id: string) {
    this.loading = true;
    try {
      let res = await API.joinGame(gs_id, "");
    } catch (err) {
      console.error(err);
    }
  }
  copyCodeToClipBoard() {
    // Copy the text inside the text field
    navigator.clipboard.writeText(this.game_session_id);

    // Alert the copied text
    alert(`'${this.game_session_id}' Copied to clipboard!`);
  }
}
