
import { Options, Vue } from "vue-class-component";
import { PropType } from "vue";
import { BoardWord, SortedWords, Game } from "./CodeNameTypes";

import GamePlay from "./GamePlay.vue";

@Options({
  props: {
    role: String,
    board: Array as PropType<BoardWord[][]>,
    sortedWords: Object as PropType<SortedWords>,
    game: Object as PropType<Game>,
  },
  components: {
    GamePlay,
  },
})
export default class GamePlayLeader extends Vue {
  loaded = false;
  role!: string;
  board!: BoardWord[][];
  sortedWords!: SortedWords;
  game!: Record<string, any>;
  myKey!: string;
  opponentKey!: string;
  deathWord!: BoardWord;
  ROLES: Record<string, any> = {
    team_a: {
      role: "Blue Team Leader",
      style: {
        color: "red",
      },
    },
    team_b: {
      role: "Blue Team Leader",
      style: {
        color: "blue",
      },
    },
  };
  mounted() {
    console.log("LEADER VIEW LOADED");
    if (this.role == "ROLE_LEAD_A") {
      this.myKey = "team_a";
      this.opponentKey = "team_b";
    } else {
      this.myKey = "team_b";
      this.opponentKey = "team_a";
    }
    this.deathWord = this.sortedWords.death_word!;
    this.loaded = true;
  }

  getMyStyle() {
    return this.ROLES[this.myKey]["style"];
  }

  getOpponentStyle() {
    return this.ROLES[this.opponentKey].style;
  }

  isMyTurn() {
    return this.game[this.myKey].is_turn;
  }

  changeTurnClicked() {
    if (!this.isMyTurn()) return;
    this.$emit("changeTurn");
  }
  getWordStyle(word: BoardWord) {
    let style = {
      "text-decoration": "none",
    };
    if (word.selected) {
      style["text-decoration"] = "line-through";
    }
    return style;
  }

  getRole() {
    return this.ROLES[this.myKey].role;
  }
}
