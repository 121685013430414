
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    msg: String,
  },
})
export default class JoinGame extends Vue {
  msg!: string;
  gameId = "";

  emitJoin() {
    if (this.gameId.trim() == "") return;
    console.log("join...");
    this.$emit("join", this.gameId);
  }
}
